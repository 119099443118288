<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="add-button">
          <v-btn outlined @click="onAdd()">
            <v-icon left>mdi-plus</v-icon>Add
          </v-btn>
        </div>
      </v-col>
      <v-col>
        <v-treeview
          v-model="selection"
          item-text="text"
          :items="menuItems"
          selection-type="independent"
          selectable
          return-object
          open-all
        >
          <template v-slot:prepend="{ item }">
            <v-icon v-text="item.icon"></v-icon>
          </template>
        </v-treeview>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="8">
        <template v-if="!show"> No Menu Item Selected </template>
        <template v-else>
          <h1>Menu Item</h1>
          <v-row>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12">
              <v-text-field
                v-model="selectedMenuItem.text"
                label="Text"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12">
              <v-text-field
                v-model="selectedMenuItem.to"
                label="To"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12">
              <v-select
                :items="pages"
                label="Page"
                item-text="name"
                item-value="id"
                v-model="selectedMenuItem.pageId"
              >
              </v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12">
              <v-select
                :items="parents"
                label="Parent"
                item-text="text"
                item-value="id"
                v-model="selectedMenuItem.parentId"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" xl="12" lg="12" md="12" sm="12">
              <v-select
                chips
                deletable-chips
                small-chips
                multiple
                :items="roles"
                label="Rules"
                v-model="selectedRoles"
              >
              </v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12">
              <v-checkbox
                v-model="selectedMenuItem.bottomShow"
                label="Bottom Show"
              ></v-checkbox>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="6" sm="12">
              <v-select
                label="Breakpoint"
                :items="breakpointItems"
                v-model="selectedMenuItem.breakpoint"
              ></v-select>
            </v-col>
            <v-col cols="12" xl="3" lg="3" md="3" sm="11">
              <v-text-field
                v-model="selectedMenuItem.icon"
                label="Icon"
              ></v-text-field>
            </v-col>
            <v-col cols="12" xl="1" lg="1" md="1" sm="1">
              <v-icon>{{ selectedMenuItem.icon }}</v-icon>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="12">
              <v-text-field
                v-model="selectedMenuItem.order"
                type="number"
                label="Order"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="action-buttons">
            <v-spacer></v-spacer>
            <div class="delete-button">
              <v-btn disabled="selectedMenuItem.id == 0" outlined class="button" @click="onDelete()">
                <v-icon left>mdi-delete</v-icon>Delete
              </v-btn>
            </div>
            <v-btn outlined class="button" @click="onSave()">
              <v-icon left>mdi-content-save</v-icon>Save
            </v-btn>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import apiService from "@/_services/api-service";
import router from "@/router";
import store from "@/store";

export default {
  created() {
    this.get();
  },

  data: () => ({
    adding: false,
    breakpointItems: [3, 4, 5, 6],
    menuItems: [],
    pages: [],
    parents: [{ id: null, text: "None" }],
    roles: ["LoggedIn", "LoggedOut"],
    selection: [],
    selectedMenuItem: null,
    selectedRoles: [],
    show: false,
  }),

  methods: {
    get() {
      let url = "roles";
      apiService.get(url, this.getRolesSuccess, this.getRolesError);
      url = "page";
      apiService.get(url, this.getPageSuccess, this.getPageError);
      apiService.get("menuitem", this.getSuccess, this.getError);
    },

    getSuccess(result) {
      const items = result.data;
      this.menuItems = [];
      items.forEach((item) => {
        if (item.parentId == null) {
          item.children = [];
          this.menuItems.push(item);
        }
      });
      items.forEach((item) => {
        if (item.parentId) {
          // Find the parent menu item
          const parent = items.find((i) => i.id == item.parentId);
          if (parent) {
            parent.children.push(item);
          }
        }
      });
      this.parents = [{ id: null, text: "None" }];
      const menuItems = result.data;
      menuItems.forEach((item) => {
        // Only allow parent menu items
        if (item.parentId == null) {
          this.parents.push({ id: item.id, text: item.text });
        }
      });
    },

    getError(error) {
      const notification = {
        title: "Error getting the Menu Items",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getRolesSuccess(result) {
      const roles = result.data;
      this.roles = ["LoggedIn", "LoggedOut"];
      roles.forEach((role) => {
        this.roles.push(role.roleName);
      });
    },

    getRolesError(error) {
      const notification = {
        title: "Error getting the Roles",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getPageSuccess(result) {
      this.pages = result.data;
      const none = {
        id: 0,
        name: "None",
      };
      this.pages.push(none);
    },

    getPageError(error) {
      const notification = {
        title: "Error getting the Pages",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    getSelectedRoles() {
      this.selectedMenuItem.rules = "";
      this.selectedRoles.forEach((role) => {
        if (this.selectedMenuItem.rules.length > 0) {
          this.selectedMenuItem.rules += ", ";
        }
        this.selectedMenuItem.rules += role;
      });

      console.log("Get Selected Roles: ", this.selectedMenuItem.rules);
    },

    onAdd() {
      this.adding = true
      this.selection = []
      this.selectedMenuItem = {
        id: 0,
        bottomShow: 0,
        breakpoint: 6,
        icon: "mdi-",
        order: 1,
        pageId: 0,
        parentId: 0,
        rules: "",
        text: "",
        to: "",
      };
      this.show = true;
    },

    onDelete() {
      let url = "menuitem";
      if (this.selectedMenuItem.id > 0) {
        url += "/" + this.selectedMenuItem.id;
        apiService.delete(url, this.deleteSuccess, this.deleteError);
      }
    },

    deleteSuccess() {
      const notification = {
        title: "Menu Item",
        status: "success",
        messages: ["Menu Item Deleted"],
      };
      store.commit("setNotification", notification);
      apiService.get("menuitem", this.getSuccess, this.getError);
    },

    deleteError(error) {
      const notification = {
        title: "Error deleting the Menu Item",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onSave() {
      this.getSelectedRoles();
      let url = "menuitem";
      if (this.selectedMenuItem.id > 0) {
        url += "/" + this.selectedMenuItem.id;
        apiService.put(
          url,
          this.selectedMenuItem,
          this.saveSuccess,
          this.saveError
        );
      } else {
        apiService.post(
          url,
          this.selectedMenuItem,
          this.saveSuccess,
          this.saveError
        );
      }
    },
    saveSuccess() {
      const notification = {
        title: "Menu Item",
        status: "success",
        messages: ["Menu Item Saved"],
      };
      store.commit("setNotification", notification);
      apiService.get("menuitem", this.getSuccess, this.getError);
    },

    saveError(error) {
      const notification = {
        title: "Error saving the Menu Item",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    setSelectedRoles() {
      this.selectedRoles = [];
      if (this.selectedMenuItem.rules == null) {
        this.selectedMenuItem.rules = "";
      }
      const rules = this.selectedMenuItem.rules.split(",");
      rules.forEach((role) => {
        role = role.trim();
        console.log("Adding role: '", role, "'");
        this.selectedRoles.push(role);
      });
      console.log("Selected Roles:", this.selectedRoles);
    },
  },

  router,
  store,

  watch: {
    selection: function () {
      if(this.adding) {
        this.adding = false
        return
      }
      switch (this.selection.length) {
        case 0:
          this.selectedMenuItem = null;
          break;

        case 1:
          this.selectedMenuItem = this.selection[0];
          this.setSelectedRoles();
          break;

        case 2:
          var index = 0;
          for (var i = 0; i < this.selection.length; i++) {
            if (this.selection[i].id == this.selectedMenuItem.id) {
              index = i;
            }
          }
          this.selection.splice(index, 1);
          this.selectedMenuItem = this.selection[0];
          break;
      }
      this.show = this.selectedMenuItem != null;
    },
  },
};
</script>

<style>
.add-button {
  padding-left: 40px;
  padding-top: 20px;
}
.delete-button {
  padding-right: 20px;
}
</style>