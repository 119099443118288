<template>
  <v-container>
    <h1>Pages</h1>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="pages">
          <template v-slot:header.edit>
            <v-btn outlined @click="onEdit(0)">
              <v-icon left>mdi-plus</v-icon>Add
            </v-btn>
          </template>
          <template v-slot:item.edit="{ item }">
            <v-btn outlined @click="onEdit(item.id)">
              <v-icon left>mdi-pencil</v-icon>Edit
            </v-btn>
          </template>
          <template v-slot:item.delete="{ item }">
            <v-btn outlined @click="onDelete(item.id)">
              <v-icon left>mdi-delete</v-icon>Delete
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="dialog.show" width="300">
      <v-card>
        <v-card-title>{{ dialog.title }}</v-card-title>
        <v-card-text>{{ dialog.description }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="button" icon outline @click="onDialogNo">
            <v-icon>mdi-close</v-icon>No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="button" icon outline @click="onDialogYes">
            <v-icon>mdi-delete</v-icon>Yes
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service"
import router from "@/router"
import store from "@/store"

export default {
  created() {
    this.get();
  },

  data: () => ({
    dialog: {
      id: 0,
      description: "",
      title: "",
      show: false,
    },
    headers: [
      {
        text: "Id",
        align: "end",
        groupable: false,
        sortable: true,
        value: "id",
      },
      {
        text: "Name",
        align: "start",
        groupable: false,
        sortable: true,
        value: "name",
      },
      {
        text: "Path",
        align: "start",
        groupable: false,
        sortable: true,
        value: "path",
      },
      {
        text: "Include",
        align: "start",
        groupable: false,
        sortable: true,
        value: "includeInSiteMap",
      },
      // {
      //   text: "Sections",
      //   align: "start",
      //   groupable: false,
      //   sortable: true,
      //   value: "sections.length",
      // },
      {
        text: "",
        align: "start",
        groupable: false,
        sortable: false,
        value: "edit",
      },
      {
        text: "",
        align: "start",
        groupable: false,
        sortable: true,
        value: "delete",
      },
    ],
    pages: [],
  }),

  methods: {
    get() {
      this.pages = [];
      apiService.get("page", this.getSuccess, this.getError);
    },

    getSuccess(result) {
      this.pages = result.data;
    },

    getError(error) {
      const notification = {
        title: "Error getting the pages",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onEdit(id) {
      router.push("/pages/" + id);
    },

    onDelete(id) {
      this.dialog.id = id
      const page = this.pages.find((s) => s.id == id)
      this.dialog.title = "Delete Page?"
      this.dialog.description =
        "Delete page with name " + page.name
      this.dialog.show = true
    },

    onDialogNo() {
      this.dialog.id = 0;
      this.dialog.show = false;
    },

    onDialogYes() {
      const url = "page/" + this.dialog.id;
      apiService.delete(url, this.deleteSuccess, this.deleteError);
      this.onDialogNo();
    },

    deleteSuccess(){
      const notification = {
        title: "Page Deleted",
        status: "success",
      };
      store.commit("setNotification", notification);
      this.get();
    },

    deleteError(error) {
      const notification = {
        title: "Problem deleting the page",
        status: "error",
        error: error,
      };
      store.commit("setResponseError", notification);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>